<template>
  <div class="login-form-wrp flex-box">
    <a-form class="flex-box" :form="form" @submit="handleSubmit">
      <a-form-item>
        <h1>{{ settings.title }}</h1>
      </a-form-item>
      <a-form-item name="name">
        <a-input
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '请输入账号!' }] },
          ]"
          placeholder="请输入手机号"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item name="password">
        <a-input
          v-decorator="[
            'password',
            {
              rules: [{ required: true, message: '请输入密码!' }],
            },
          ]"
          type="password"
          placeholder="请输入密码"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="link" @click="forgetPwd">忘记密码</a-button>
      </a-form-item>
      <a-form-item>
        <a-button class="submit-btn" type="primary" html-type="submit">
          登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import notification from "ant-design-vue/es/notification";
import { mapGetters } from "vuex";
import { timeFix } from "@/utils/time";
import { hexSha1 } from "@/utils/sha1";
import defaultSettings from "@/config/defaultSettings";

export default {
  data() {
    return {
      settings: defaultSettings,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const { name, password } = values;
          this.$store
            .dispatch("Login", {
              name,
              password: hexSha1(password).toUpperCase(),
              projectId: this.settings.projectId,
            })
            .then(() => {
              // this.$store
              //   .dispatch("GetProjectList", this.userInfo.userId)
              //   .then(() => {
              this.$router.push({
                path: this.$route.query.redirect || "/",
              });
              setTimeout(() => {
                notification.success({
                  message: "欢迎",
                  description: `${timeFix()}，欢迎回来`,
                });
              }, 1000);
              //   });
            });
        }
      });
    },
    forgetPwd() {
      notification.warning({
        message: "提醒",
        description: "请联系管理员修改密码",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-form-wrp {
  position: fixed;
  top: 50%;
  right: 150px;
  transform: translateY(-50%);
  width: 550px;
  height: 500px;
  background-color: #fff;
  border-radius: 20px;
  /deep/ .ant-form {
    width: 80%;
    height: 80%;
    .ant-row {
      width: 100%;
      // height: 25%;
      .ant-col {
        width: 100% !important;
        height: auto;
        .ant-input-affix-wrapper {
          height: 50px;
          border-radius: 25px;
          font-size: 18px;
        }
        .ant-input {
          font-size: 16px;
          height: 50px;
          border-radius: 25px;
          padding-left: 34px;
        }
        h1 {
          text-align: center;
          line-height: 40px;
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
    .submit-btn {
      width: 100%;
      height: 50px;
      border-radius: 25px;
      font-size: 16px;
    }
  }
}
</style>
