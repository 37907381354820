/* eslint-disable no-array-constructor */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable camelcase */

const hexcase = 0;
const b64pad = "";
const chrsz = 8;

const binb2hex = (binarray) => {
  const hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
  let str = "";
  for (let i = 0; i < binarray.length * 4; i++) {
    str +=
      hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
      hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
  }
  return str;
};

const rol = (num, cnt) => (num << cnt) | (num >>> (32 - cnt));

const safe_add = (x, y) => {
  const lsw = (x & 0xffff) + (y & 0xffff);
  const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
  return (msw << 16) | (lsw & 0xffff);
};

const sha1_ft = (t, b, c, d) => {
  if (t < 20) return (b & c) | (~b & d);
  if (t < 40) return b ^ c ^ d;
  if (t < 60) return (b & c) | (b & d) | (c & d);
  return b ^ c ^ d;
};

const sha1_kt = (t) =>
  t < 20 ? 1518500249 : t < 40 ? 1859775393 : t < 60 ? -1894007588 : -899497514;

const core_sha1 = (x, len) => {
  x[len >> 5] |= 0x80 << (24 - (len % 32));
  x[(((len + 64) >> 9) << 4) + 15] = len;

  const w = Array(80);
  let a = 1732584193;
  let b = -271733879;
  let c = -1732584194;
  let d = 271733878;
  let e = -1009589776;

  for (let i = 0; i < x.length; i += 16) {
    const olda = a;
    const oldb = b;
    const oldc = c;
    const oldd = d;
    const olde = e;

    for (let j = 0; j < 80; j++) {
      if (j < 16) w[j] = x[i + j];
      else w[j] = rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
      const t = safe_add(
        safe_add(rol(a, 5), sha1_ft(j, b, c, d)),
        safe_add(safe_add(e, w[j]), sha1_kt(j))
      );
      e = d;
      d = c;
      c = rol(b, 30);
      b = a;
      a = t;
    }
    a = safe_add(a, olda);
    b = safe_add(b, oldb);
    c = safe_add(c, oldc);
    d = safe_add(d, oldd);
    e = safe_add(e, olde);
  }
  return Array(a, b, c, d, e);
};

const str2binb = (str) => {
  const bin = Array();
  const mask = (1 << chrsz) - 1;
  for (let i = 0; i < str.length * chrsz; i += chrsz)
    bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
  return bin;
};

const binb2b64 = (binarray) => {
  const tab =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let str = "";
  for (let i = 0; i < binarray.length * 4; i += 3) {
    const triplet =
      (((binarray[i >> 2] >> (8 * (3 - (i % 4)))) & 0xff) << 16) |
      (((binarray[(i + 1) >> 2] >> (8 * (3 - ((i + 1) % 4)))) & 0xff) << 8) |
      ((binarray[(i + 2) >> 2] >> (8 * (3 - ((i + 2) % 4)))) & 0xff);
    for (let j = 0; j < 4; j++) {
      if (i * 8 + j * 6 > binarray.length * 32) str += b64pad;
      else str += tab.charAt((triplet >> (6 * (3 - j))) & 0x3f);
    }
  }
  return str;
};

const binb2str = (bin) => {
  let str = "";
  const mask = (1 << chrsz) - 1;
  for (let i = 0; i < bin.length * 32; i += chrsz)
    str += String.fromCharCode((bin[i >> 5] >>> (24 - (i % 32))) & mask);
  return str;
};

const core_hmac_sha1 = (key, data) => {
  let bkey = str2binb(key);
  if (bkey.length > 16) bkey = core_sha1(bkey, key.length * chrsz);
  const ipad = Array(16);
  const opad = Array(16);
  for (let i = 0; i < 16; i++) {
    ipad[i] = bkey[i] ^ 0x36363636;
    opad[i] = bkey[i] ^ 0x5c5c5c5c;
  }
  const hash = core_sha1(
    ipad.concat(str2binb(data)),
    512 + data.length * chrsz
  );
  return core_sha1(opad.concat(hash), 512 + 160);
};

export function hexSha1(s) {
  return binb2hex(core_sha1(str2binb(s), s.length * chrsz));
}

export function b64_sha1(s) {
  return binb2b64(core_sha1(str2binb(s), s.length * chrsz));
}

export function str_sha1(s) {
  return binb2str(core_sha1(str2binb(s), s.length * chrsz));
}

export function hex_hmac_sha1(key, data) {
  return binb2hex(core_hmac_sha1(key, data));
}

export function b64_hmac_sha1(key, data) {
  return binb2b64(core_hmac_sha1(key, data));
}

export function str_hmac_sha1(key, data) {
  return binb2str(core_hmac_sha1(key, data));
}
